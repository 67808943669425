<!-- 忘记密码 -->
<template>
  <div class="login-cont">
    <div class="login-logo">
      <div class="login-logo-img"><img src="../../../public/static/logo.png" /></div>
      <span class="login-logo-text">海南中和职业培训中心</span>
    </div>
    <div class="forget-cont">
      <div class="forget-cont-title">找回密码</div>
      <div class="forget-cont-form">
        <div class="forget-cont-form-input">
          <div class="forget-cont-form-input-label">登录账号</div>
          <input class="forget-cont-form-input-right" placeholder-class="forget-cont-form-input-placeholder" type="text"
            v-model="account" name="account" placeholder="身份证号/手机号/用户名">
        </div>
        <div class="forget-cont-form-input">
          <div class="forget-cont-form-input-label">手机号码</div>
          <input class="forget-cont-form-input-right" placeholder-class="forget-cont-form-input-placeholder"
            v-model="mobile" name="mobile" placeholder="请输入手机号码">
        </div>
		<div class="forget-cont-form-input">
		  <div class="forget-cont-form-input-label">图形码</div>
		  <input class="forget-cont-form-input-right-yzm"  placeholder-class="reg-form-cont-placeholder"
			v-model="randcode" name="randcode" placeholder="请输入图形码">
		  <img class="captcha" :src="captchaUrl" alt="图形码" @click="refreshCaptcha" />
		  
		</div>
        <div class="forget-cont-form-input">
          <div class="forget-cont-form-input-label">验证码</div>
          <input class="forget-cont-form-input-right-yzm" placeholder-class="forget-cont-form-input-placeholder"
            v-model="code" name="code" placeholder="请输入验证码">
          <div type="button" class="forget-cont-form-btn-yzm" @click="sendBtn">发送验证码</div>
        </div>
        <div class="forget-cont-form-input">
          <div class="forget-cont-form-input-label">设置新密码</div>
          <input class="forget-cont-form-input-right" placeholder-class="forget-cont-form-input-placeholder"
            v-model="newpwd" name="newpwd" placeholder="由6-16位的英文字母、数字组成">
        </div>
        <div class="forget-cont-form-input">
          <div class="forget-cont-form-input-label">确认新密码</div>
          <input class="forget-cont-form-input-right" placeholder-class="forget-cont-form-input-placeholder"
            v-model="renewpwd" name="renewpwd" placeholder="需与登录密码保持一致">
        </div>
        <div type="button" class="forget-cont-form-btn-submit" @click="tijiaoBtn">确认修改</div>
      </div>
    </div>
    <div class="login-form-cont-copyright">
      Copyright © 2022 海南中和职业培训中心有限公司 版权所有 保留一切权利 | <a href="https://beian.miit.gov.cn/"
        target="_blank">琼ICP备2022007877号-1</a>
    </div>

  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        account: '',
        mobile: '',
        code: '',
        newpwd: '',
        renewpwd: '',
		randcode:'',
		captchaUrl:'',
      };
    },
    created() {
      let that = this;
		that.refreshCaptcha();
    },
    methods: {
		refreshCaptcha() {
			let that = this;
			that.captchaUrl = `https://zh-api.hnzhonghe.cn/api/v1/auth/verifyCode?${new Date().getTime()}`; // 防止缓存
		},
      tijiaoBtn() {
        let that = this;
        if (!that.account) {
          that.$myMessage.warning('请输入身份证号/手机号/用户名！');
          return false;
        }
        if (!that.mobile) {
          that.$myMessage.warning('请输入手机号码！');
          return false;
        }
        if (!that.code) {
          that.$myMessage.warning('请输入验证码！');
          return false;
        }
        if (!that.newpwd) {
          that.$myMessage.warning('请输入新密码！');
          return false;
        }
        if (that.newpwd != that.renewpwd) {
          that.$myMessage.warning('两次密码不一致，请重新输入！');
          return false;
        }
        if (!that.renewpwd) {
          that.$myMessage.warning('请输入确认新密码！');
          return false;
        }
        let param = {
          account: that.account,
          mobile: that.mobile,
          code: that.code,
          newpwd: that.newpwd,
          renewpwd: that.renewpwd,
        };
        that.$request.forgetPwd(param).then((res) => {
          if (res.code == 200) {
            that.$myMessage.success(res.msg);
            that.loading = true
            setTimeout(function() {
              that.$router.replace('/login')
            }, 2000)
          }
        });
      },
      sendBtn() {
        let that = this;
        if (!that.mobile) {
          that.$myMessage.warning('请输入手机号码！');
          return false;
        }
        if (!that.randcode) {
          that.$myMessage.warning('业务停机！');
          return false;
        }
        let param = {
          mobile: that.mobile,
		  randcode: that.randcode,
          type: 'hasreg',
        };
        that.$request.sendCodeBefor(param).then((res) => {
          if (res.code == 200) {
            that.$myMessage.success(res.msg);
            that.loading = true
          }
        });
      }
    },
  };
</script>

<style scoped lang="css">
  .login-cont {
    height: 100vh;
    background-color: #F5F6FA;
  }

  .login-logo {
    padding: 1.2rem 5rem;
    display: flex;
    align-items: center;
    background-color: #fff;
  }

  .login-logo-img {
    margin-right: 1.5rem;
    width: 3rem;
    height: 3rem;
  }

  .login-logo-img img {
    width: 100%;
    height: 100%;
  }

  .login-logo-text {
    font-size: 1.5rem;
    color: #3D3D3D;
  }

  .forget-cont {
    margin: 2rem auto;
    padding-bottom: 10rem;
    width: 70%;
    background-color: #fff;
    text-align: center;
  }

  .forget-cont-title {
    font-size: 1.4rem;
    line-height: 4;
  }

  .forget-cont-form-input {
    margin-bottom: 1.2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .forget-cont-form-input .captcha{
  	  width: 10rem;
  }
  .forget-cont-form-input-label:before {
    content: "*";
    color: #FA5151;
  }

  .forget-cont-form-input-label {
    margin-right: 1rem;
    width: 6%;
    color: #606266;
    font-size: 0.7rem;
    text-align: right;
  }

  .forget-cont-form-input-right {
    width: 60%;
    outline: none;
    border: 1px solid #DCDFE6;
    border-radius: 0.2rem;
    text-indent: 4px;
    line-height: 2;
  }

  .forget-cont-form-input-right-yzm {
    width: 47%;
    outline: none;
    border: 1px solid #DCDFE6;
    border-radius: 0.2rem;
    text-indent: 4px;
    line-height: 2;
  }

  .forget-cont-form-input-placeholder {
    color: #C0C4CC;
  }

  .forget-cont-form-btn-yzm {
    margin-left: 3%;
    width: 10%;
    color: #fff;
    background-color: #0074FF;
    font-size: 0.7rem;
    line-height: 2.3;
  }

  .forget-cont-form-btn-submit {
    margin: 5rem auto 0;
    width: 10%;
    color: #fff;
    background-color: #0074FF;
    font-size: 0.7rem;
    line-height: 3;
  }

  .login-form-cont-copyright {
    position: fixed;
    z-index: 1;
    bottom: 2rem;
    width: 100%;
    color: #3D3D3D;
    font-size: 0.7rem;
    text-align: center;
  }

  .login-form-cont-copyright a {
    color: #3D3D3D;
    text-decoration: none;
  }
</style>
